/* eslint-disable jsx-a11y/img-redundant-alt */
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import config from "../../../services/config";
import { get } from "../../../services/cache";
import { t } from "i18next";
import PhotoGallery from './../Photo/PhotoGallery';

export const AddPhoto = () => {
    const [selectedFiles, setSelectedFiles] = useState([]);
    const [selectedFilesToShow, setSelectedFilesToShow] = useState([]);
    const [productData, setProductData] = useState([]);
    const [loading, setLoading] = useState(null);
    const [error, setError] = useState(null);
    const userAccessToken = get("fedidLoginAccessToken");
    const redirect = useNavigate();

    const [base64DataList, setBase64DataList] = useState([]);
    const [imageSrcList, setImageSrcList] = useState([]);

    useEffect(() => {
        fetch(`${config.baseUrl}/v1/products/medias/${get("AddProductData")?.data?.id}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
            },
            credentials: "same-origin",
        })
            .then((response) => response.json())
            .then((data) => setBase64DataList(data.data.map(item => item.fileBytes)))
            .catch((error) => console.log(error));
    }, []);

    useEffect(() => {
        const newImageSrcList = [];

        if (base64DataList && base64DataList.length > 0) {
            base64DataList.forEach((base64Data) => {
                if (base64Data) {
                    const binaryData = atob(base64Data);
                    const imageData = new Uint8Array(binaryData.length);

                    for (let i = 0; i < binaryData.length; i++) {
                        imageData[i] = binaryData.charCodeAt(i);
                    }

                    const blob = new Blob([imageData], { type: "image/jpeg" });
                    const imageUrl = URL.createObjectURL(blob);

                    newImageSrcList.push(imageUrl);
                }
            });
        }

        setImageSrcList(newImageSrcList);
    }, [base64DataList]);

    const updatePhoto = async () => {
        if (selectedFiles.length > 0) {
            try {
                await Promise.all(selectedFiles.map(file => {

                    const formData = new FormData();
                    formData.append("file", file);
                    setLoading(true);

                    return fetch(`${config.baseUrl}/v1/products/medias?productId=${get("AddProductData")?.data?.id}`, {
                        body: formData,
                        method: "POST",
                        headers: {
                            Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                            "x-api-key": config.apiKey,
                        },
                    })
                        .then((response) => {
                            if (!response.ok) {
                                throw new Error("Photo upload error");
                            }
                        });
                }));

                redirect('/sh-add-product/add-product-detail-buyback');
            } catch (error) {
                console.error("An unexpected error occurred:", error);
                setLoading(false);
                setError(true);
            }
        } else if (selectedFiles.length === 0) {
            redirect('/sh-add-product/add-product-detail-buyback');
        }
    };

    useEffect(() => {
        fetch(`${config.baseUrl}/v1/products/details/${get("AddProductData").data?.modelCode}`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
            },
            credentials: "same-origin",
        })
            .then((response) => response.json())
            .then((res) => {
                setProductData(res.data)
            })
            .catch((error) => console.log(error));
    }, []);

    const handleFileChange = (event) => {
        const files = Array.from(event.target.files);
        setSelectedFilesToShow(prevSelectedFiles => [...prevSelectedFiles, ...files]);
        setSelectedFiles(prevSelectedFiles => [...prevSelectedFiles, ...files]);
    };

    const deletePhoto = (index) => {
        const updatedSelectedFilesToShow = [...selectedFilesToShow];
        updatedSelectedFilesToShow.splice(index, 1);
        setSelectedFilesToShow(updatedSelectedFilesToShow);

        const updatedSelectedFiles = [...selectedFiles];
        updatedSelectedFiles.splice(index, 1);
        setSelectedFiles(updatedSelectedFiles);
    };

    return (
        <div className="w-full items-center flex justify-center mt-[3rem] flex-col">
            <div className="productCard bg-white m-auto w-11/12 rounded-lg my-6 border-2 px-4" >
                <div className="flex place-content-between my-4 rounded border cursor-pointer" >
                    <div className="w-1/3 relative">
                        <div className="max-w-full h-auto p-2 mb-5">
                            {get("AddProductDetails")?.data ?
                                <img src={get("AddProductDetails")?.data?.mainImageUrl} alt="" />
                                :
                                <svg
                                    width="118"
                                    height="110"
                                    viewBox="0 0 118 110"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="max-w-full h-auto m-auto"
                                >
                                    <path
                                        d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                </svg>
                            }
                        </div>
                        <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">{productData?.referenceCode}</span>
                    </div>
                    <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5 relative">
                        <div className="flex justify-between items-center py-1.5">
                            <span className="font-bold">{productData?.brand}</span>
                        </div>
                        <span>{productData?.title}</span>
                    </div>
                </div>
            </div>
            {(imageSrcList.length >= 5 || selectedFilesToShow.length >= 5) && <p className="my-1">{t("MaxPhoto")}</p>}

            <div className="productCard w-11/12 photoGrid justify-items-center imgContainer" style={fileContainerStyles}>
                {imageSrcList.length > 0 && imageSrcList.map((src, index) => (
                    <img
                        key={index}
                        style={imageStyles}
                        className="border my-1 w-[100px] h-[100px] object-cover"
                        src={src}
                        alt={`Image Number: ${index}`}
                    />
                ))}
                {(selectedFilesToShow.length > 0 && selectedFilesToShow.length <= 5) && (
                    <PhotoGallery
                        photos={selectedFilesToShow}
                        onDeletePhoto={deletePhoto}
                    />
                )}
            </div>

            {loading || error ? (
                <div className="absolute w-full h-full top-0 z-50 flex items-center justify-center" style={{ backgroundColor: "#000000b8" }}>
                    <div className="border rounded-lg bg-white p-12 text-center">
                        {loading && <>
                            <svg aria-hidden="true" className="w-full h-16 text-gray-200 animate-spin dark:text-gray-600 fill-blue-600 mb-3" viewBox="0 0 100 101" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M100 50.5908C100 78.2051 77.6142 100.591 50 100.591C22.3858 100.591 0 78.2051 0 50.5908C0 22.9766 22.3858 0.59082 50 0.59082C77.6142 0.59082 100 22.9766 100 50.5908ZM9.08144 50.5908C9.08144 73.1895 27.4013 91.5094 50 91.5094C72.5987 91.5094 90.9186 73.1895 90.9186 50.5908C90.9186 27.9921 72.5987 9.67226 50 9.67226C27.4013 9.67226 9.08144 27.9921 9.08144 50.5908Z" fill="currentColor" />
                                <path d="M93.9676 39.0409C96.393 38.4038 97.8624 35.9116 97.0079 33.5539C95.2932 28.8227 92.871 24.3692 89.8167 20.348C85.8452 15.1192 80.8826 10.7238 75.2124 7.41289C69.5422 4.10194 63.2754 1.94025 56.7698 1.05124C51.7666 0.367541 46.6976 0.446843 41.7345 1.27873C39.2613 1.69328 37.813 4.19778 38.4501 6.62326C39.0873 9.04874 41.5694 10.4717 44.0505 10.1071C47.8511 9.54855 51.7191 9.52689 55.5402 10.0491C60.8642 10.7766 65.9928 12.5457 70.6331 15.2552C75.2735 17.9648 79.3347 21.5619 82.5849 25.841C84.9175 28.9121 86.7997 32.2913 88.1811 35.8758C89.083 38.2158 91.5421 39.6781 93.9676 39.0409Z" fill="currentFill" />
                            </svg>
                            <span className="text-black text-lg tracking-wide">
                                {t("AddingPhotos")}
                            </span>
                        </>}

                        {error &&
                            <>
                                <svg className="w-full h-16 mb-3" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
                                    <path fillRule="evenodd" clipRule="evenodd" d="M14.6113 13.3333L8.26066 2.3333C8.14157 2.12705 7.9215 2 7.68333 2C7.44517 2 7.22509 2.12705 7.106 2.3333L0.755332 13.3333C0.636245 13.5396 0.636243 13.7937 0.755328 14C0.874412 14.2062 1.09449 14.3333 1.33267 14.3333H14.034C14.2722 14.3333 14.4923 14.2062 14.6113 14C14.7304 13.7937 14.7304 13.5396 14.6113 13.3333ZM12.8793 13H2.48735L7.68335 3.99996L12.8793 13ZM7.0167 11H8.35003V12.3333H7.0167V11ZM8.35003 6.33329H7.0167V9.66662H8.35003V6.33329Z" fill="#001018" />
                                </svg>
                                <div className="text-black text-lg mb-5">
                                    {t("ErrorDuringIns")}
                                </div>
                                <button className="border rounded-lg p-3 border-black" onClick={()=> window.location.reload()}>
                                {t("TryAgain")}
                            </button>
                    </>}
                </div>
                </div>
    ) : <></>
}


            <div style={dropzoneStyles} className="productCard w-11/12 fixed bottom-[90px]">
                <input type="file" id="file-input" name="file-input" accept=".jpg, .png, .pjpeg, .jpeg" className={`file-input__input ${imageSrcList.length >= 5 ? "opacity-40" : ''} `} multiple onChange={handleFileChange} disabled={imageSrcList.length >= 5 || selectedFiles.length >= 5} />
                <label className={`file-input__label  ${imageSrcList.length >= 5 ? "opacity-40" : ''}`} htmlFor="file-input">
                    <svg width="33" height="33" viewBox="0 0 33 33" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <g clipPath="url(#clip0_1940_6620)">
                            <path d="M28.8709 20.8506V24.8506H32.8709V27.5173H28.8709V31.5173H26.2043V27.5173H22.2043V24.8506H26.2043V20.8506H28.8709ZM28.8816 6.85059C29.6123 6.85059 30.2043 7.44392 30.2043 8.17459V18.1839H27.5376V9.51725H6.20426V26.1826L19.5376 26.1839V28.8506H4.86026C4.50935 28.8502 4.17293 28.7106 3.92492 28.4623C3.67691 28.2141 3.5376 27.8775 3.5376 27.5266V8.17459C3.54004 7.82442 3.68013 7.48928 3.92761 7.24154C4.1751 6.99381 4.5101 6.85338 4.86026 6.85059H28.8816Z" fill="#001018" />
                            <path d="M17.5379 22.1842C18.5988 22.1842 19.6162 21.7628 20.3664 21.0127C21.1165 20.2625 21.5379 19.2451 21.5379 18.1842C21.5379 17.1234 21.1165 16.106 20.3664 15.3558C19.6162 14.6057 18.5988 14.1842 17.5379 14.1842C16.4771 14.1842 15.4596 14.6057 14.7095 15.3558C13.9594 16.106 13.5379 17.1234 13.5379 18.1842C13.5379 19.2451 13.9594 20.2625 14.7095 21.0127C15.4596 21.7628 16.4771 22.1842 17.5379 22.1842ZM17.5379 24.8509C15.7698 24.8509 14.0741 24.1485 12.8239 22.8983C11.5736 21.6481 10.8713 19.9524 10.8713 18.1842C10.8713 16.4161 11.5736 14.7204 12.8239 13.4702C14.0741 12.22 15.7698 11.5176 17.5379 11.5176C19.306 11.5176 21.0017 12.22 22.252 13.4702C23.5022 14.7204 24.2046 16.4161 24.2046 18.1842C24.2046 19.9524 23.5022 21.6481 22.252 22.8983C21.0017 24.1485 19.306 24.8509 17.5379 24.8509ZM6.20459 2.51758H14.2046V5.18425H6.20459V2.51758Z" fill="#001018" />
                        </g>
                        <defs>
                            <clipPath id="clip0_1940_6620">
                                <rect width="32" height="32" fill="white" transform="translate(0.871094 0.850586)" />
                            </clipPath>
                        </defs>
                    </svg>
                    <p className="text-black text-lg">{t("AddPhoto")}</p>
                </label>
            </div>
            <div className="addProductStickyButtonsBg">
                <button className="w-11/12 addProductStickyButtons z-10" onClick={updatePhoto}>{t("Save")}</button>
            </div>
        </div >
    );
};

const dropzoneStyles = {
    border: "2px dashed #cccccc",
    borderRadius: "4px",
    padding: "1px",
    textAlign: "center",
    cursor: "pointer",
};

const fileContainerStyles = {
    margin: "10px",
    position: "relative",
    width: "100%",
    height: "100px",
};

const imageStyles = {
    width: "100px",
    height: "100px",
    objectFit: "cover",
    borderRadius: "4px"
};

export default AddPhoto;
