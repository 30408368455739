import React, { useEffect, useState } from "react";
import { get, set } from "../../../services/cache";
import config from "../../../services/config";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";


export const BuyBackAddProduct = (props) => {


    const { state } = useLocation();
    const userAccessToken = get("fedidLoginAccessToken");
    const [productInfoData, setProductInfoData] = useState([]);
    const [priceWarn, setPriceWarn] = useState(null);
    const [showProductCard, setShowProductCard] = useState(true);
    const [customerDesc, setCustomerDesc] = useState(get("addCustomerDesc")?.data ? get("addCustomerDesc").data : '');
    const [itemCode, setItemCode] = useState();
    const [desc, setDesc] = useState(get("addDesc")?.data ? get("addDesc")?.data : '');
    const [sendProduct, setSendProduct] = useState();
    const [isChecked, setIsChecked] = useState(get("addDamageInfo")?.data ? get("addDamageInfo")?.data : false);
    let typeOfApp = localStorage.getItem("typeOfApp");




    const redirect = useNavigate();



    const handleCheckboxChange = (event) => {
        setIsChecked(event.target.checked);
        set({ key: "addDamageInfo", data: event.target.checked })

        fetch(`${config.baseUrl}/v1/products/${get("AddProductData")?.data?.id}/damage?damagedInWh=${event.target.checked}`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                'x-api-key': config.apiKey,
                "Content-Type": "application/json"
            },
            credentials: 'same-origin',
        })
            .then((response) => response.json())
            .catch((error) => {
                console.error(error);
            });

    };

    useEffect(() => {
        setShowProductCard(true);
    }, []);


    useEffect(() => {
        if (!userAccessToken?.data?.access_token) {
            redirect("/");
            window.location.reload();
        }

    })

    useEffect(() => {
        setProductInfoData(get("AddProductDetails").data)
        let backButton;
        backButton = document.getElementById("backButton");
        backButton.addEventListener('click', goBack);


        if (!localStorage.getItem("price") && typeOfApp === "secondLife") {
            fetch(`${config.baseUrl}/v1/products/${get("AddProductData").data?.id}/discount?discount=10`, {
                method: "PATCH",
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                    'Content-Type': 'application/json'
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((res) => localStorage.setItem("price", res.data))
                .catch((error) => console.log(error));
        }

        if (get("conditionInfo") && get("ProductAge") && get("ProductFrame") && get("buybackProduct")) {
            setPriceWarn(false);
        } else {
            setPriceWarn(true);
        }
    }, [])

    function goBack() {
        setShowProductCard(false);
    }

    const handleChange = (event) => {
        setDesc(event.target.value);
        set({ key: "addDesc", data: event.target.value })
    };
    const handleCustomerDescChange = (event) => {
        setCustomerDesc(event.target.value);
        set({ key: "addCustomerDesc", data: event.target.value })
    };

    const secondLifePriceRedirect = () => {
        setShowProductCard(false); redirect('price')
    }
    const secondLifeSaveRedirect = () => {
        redirect('add-label'); updateDesc()
    }

    const buyBackPriceRedirect = () => {
        setShowProductCard(false);
        setSendProduct(true);
        updateDesc();
    }


    const updateDesc = () => {
        if (get("claims").data.role === "SA") {
            fetch(`${config.baseUrl}/v1/products/${get("AddProductData")?.data?.id}/descriptions?${desc ? 'description=' + desc : ''}${customerDesc ? '&customerDescription=' + customerDesc :''}`, {
                method: 'POST',
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    'x-api-key': config.apiKey,
                    "Content-Type": "application/json"
                },
                credentials: 'same-origin',
            })
                .then((response) => response.json())
                .catch((error) => {
                    console.error(error);
                });
        }
        else {
            fetch(`${config.baseUrl}/v1/products/${get("AddProductData")?.data?.id}/description`, {
                method: 'PATCH',
                body: JSON.stringify(desc),
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    'x-api-key': config.apiKey,
                    "Content-Type": "application/json"
                },
                credentials: 'same-origin',
            })
                .then((response) => response.json())
                .catch((error) => {
                    console.error(error);
                });


            fetch(`${config.baseUrl}/v1/products/${get("AddProductData").data?.id}/description/customer`, {
                method: "PATCH",
                body: JSON.stringify(customerDesc),
                headers: {
                    Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                    "x-api-key": config.apiKey,
                    'Content-Type': 'application/json'
                },
                credentials: "same-origin",
            })
                .then((response) => response.json())
                .then((res) => console.log(res))
                .catch((error) => console.log(error));
        }
    }

    return (
        <div className="w-full h-full flex justify-center flex-col" style={{ background: "#fff" }} >
            <div className={`flex flex-col items-center`}>
                <div className={`w-full h-full justify-center mt-[3rem] flex `}>
                    <div className="productCard bg-white m-auto w-11/12 rounded-lg my-6 border-2 px-4" >
                        <div className="flex place-content-between my-4 rounded border cursor-pointer" >
                            <div className="w-1/3 relative">
                                <div className="max-w-full h-auto p-2 mb-5">

                                    {get("AddProductDetails")?.data ?
                                        <img src={get("AddProductDetails")?.data?.mainImageUrl} alt="" />
                                        :
                                        <svg
                                            width="118"
                                            height="110"
                                            viewBox="0 0 118 110"
                                            fill="none"
                                            xmlns="http://www.w3.org/2000/svg"
                                            className="max-w-full h-auto m-auto"
                                        >
                                            <path
                                                d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
                                                stroke="black"
                                                strokeWidth="3"
                                            />
                                            <path
                                                d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
                                                stroke="black"
                                                strokeWidth="3"
                                            />
                                            <path
                                                d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
                                                stroke="black"
                                                strokeWidth="3"
                                            />
                                        </svg>
                                    }
                                </div>
                                <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">
                                    {productInfoData?.referenceCode}
                                </span>
                            </div>
                            <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5 relative">
                                <div className="flex justify-between items-center py-1.5">
                                    <span className="font-bold">
                                        {productInfoData ? productInfoData?.brand : t("Loading")}
                                    </span>
                                </div>
                                <span>{productInfoData ? productInfoData?.title : t("Loading")}</span>
                            </div>
                        </div>
                    </div>
                    <div className="addProductStickyButtonsBg">
                        <button className={`w-11/12 addProductStickyButtons z-10 justify-center ${itemCode ? 'hidden' : 'flex'}`} onClick={() => { typeOfApp === "secondLife" ? secondLifeSaveRedirect() : buyBackPriceRedirect() }}>{t("Save")}</button>
                    </div>
                </div>


                <div className={`w-full flex flex-col items-center justify-center gap-8 productCard`} >

                    <button className="border rounded p-4 w-11/12 text-left flex items-center justify-between opacity-60" onClick={() => { setShowProductCard(false); redirect('condition') }} disabled={true}>{t("Condition")}
                        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="opacity-60">
                            <path d="M5.84001 7.10228L0.890015 2.15228L2.30401 0.738281L8.66801 7.10228L2.30401 13.4663L0.890015 12.0523L5.84001 7.10228Z" fill="#001018" />
                        </svg>
                    </button>

                    <button className="border rounded p-4 w-11/12 text-left flex items-center justify-between opacity-60" onClick={() => { setShowProductCard(false); redirect('add-information') }} disabled={true}>{t("AddProductInformation")}
                        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="opacity-60">
                            <path d="M5.84001 7.10228L0.890015 2.15228L2.30401 0.738281L8.66801 7.10228L2.30401 13.4663L0.890015 12.0523L5.84001 7.10228Z" fill="#001018" />
                        </svg>
                    </button>
                    <button className="border rounded p-4 w-11/12 text-left flex items-center justify-between opacity-60" onClick={() => { setShowProductCard(false); redirect('/sh-add-product/add-photo') }} disabled={true}>{t("AddPhoto")}
                        <svg width="9" height="14" viewBox="0 0 9 14" fill="none" xmlns="http://www.w3.org/2000/svg" className="opacity-60">
                            <path d="M5.84001 7.10228L0.890015 2.15228L2.30401 0.738281L8.66801 7.10228L2.30401 13.4663L0.890015 12.0523L5.84001 7.10228Z" fill="#001018" />
                        </svg>
                    </button>
                    <textarea name="desc" value={desc} id="" cols="30" rows="4" className="w-11/12 border border-black p-2 rounded " placeholder={`${t("AddDescription")}`} onChange={handleChange} />
                    <textarea name="desc" value={customerDesc} id="" cols="30" rows="4" className="w-11/12 border border-black p-2 rounded mb-32" placeholder={`${t("CustomerDescription")}`} onChange={handleCustomerDescChange} />
                </div>
            </div>


            <div className={`${sendProduct ? 'block' : 'hidden'}  flex items-center justify-center`}>
                <div className=" approvedPopUp" />
                <div className="flex flex-col bg-white w-4/5 h-2/5 justify-around items-center absolute z-10 top-1/3 rounded approvedPopUpScreen" >
                    <div className="p-7 rounded-full" style={{ background: "#E7F3F9" }}>
                        <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path d="M15.902 21.8765L37.5672 0.208984L40.9023 3.54173L15.902 28.542L0.902344 13.5423L4.23509 10.2096L15.902 21.8765Z" fill="#007DBC" />
                        </svg>
                    </div>
                    <span className="text-xl font-bold blue600 text-center w-4/5">{t("NewBuyBackProductSentToApproval")}</span>
                    <button className="approvedButton" onClick={() => { setSendProduct(false); redirect('/sh-homepage') }}>{t("Okay")}</button>
                </div>
            </div>

        </div>

    );

}


export default BuyBackAddProduct;