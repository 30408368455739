
import React, { useEffect, useState } from "react";
import { SHStockControlCard } from "./SHStockControlCard";
import { get } from "../../services/cache";
import config from "../../services/config";
import { useNavigate } from "react-router-dom";
import Autocomplete from "@mui/material/Autocomplete";
import TextField from "@mui/material/TextField";
import Loading from "../Loading";
import { t } from "i18next";




export const SHStockControl = () => {


  const [stockControlData, setStockControlData] = useState([]);
  const userAccessToken = get("fedidLoginAccessToken");
  const [uniqRes, setUniqRes] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const [searchData, setSearchData] = useState([]);
  const [fetchFlag, setFetchFlag] = useState(false);
  const [responseArray, setResponseArray] = useState([]);

  const redirect = useNavigate();

  let typeOfApp = localStorage.getItem("typeOfApp");

  let filteredData = stockControlData.filter(item => item.status === 4 && item.productType === (typeOfApp === 'secondLife' ? 0 : 1)).sort((a, b) => (a.id > b.id ? -1 : 1))

  useEffect(() => {
    fetch(`${config.baseUrl}/v1/products/all/stores`, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
        "x-api-key": config.apiKey,
      },
      credentials: "same-origin",
    })
      .then((response) => response.json())
      .then(res => { setStockControlData(res.data) })
      .catch((error) => console.log(error));


    if (!userAccessToken?.data?.access_token) {
      redirect("/");
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  useEffect(() => {
    const uniqueData = filteredData.reduce((accumulator, item) => {
      const isExisting = accumulator.find(obj => obj.modelCode === item.modelCode);
      if (!isExisting) {
        accumulator.push(item);
      }
      return accumulator;
    }, []);
    setUniqRes(uniqueData);

  })

  useEffect(() => {
    let search = filteredData.filter((item) => item.modelCode === selectedOption);
    setSearchData(search);
  }, [searchData])

  useEffect(() => {

    if (uniqRes.length > 0 && !fetchFlag) {
      setFetchFlag(true);
      Promise.all(uniqRes.map((item) =>
        fetch(`${config.baseUrl}/v1/products/details/${item.modelCode}`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
            "x-api-key": config.apiKey,
          },
        })
          .then((response) => response.json())
          .then((res) => {
            return res.data;
          })
          .catch((error) => console.log(error))
      )).then((responses) => {
        setResponseArray(responses)
      })
        .catch((error) => console.log(error));
    }

  }, [uniqRes, fetchFlag]);

  useEffect(() => {

    if (responseArray.length > 0) {
      if (
        responseArray.sort((a, b) => a - b).toString() !==
        JSON.parse(localStorage.getItem("stockControlProducts"))?.sort((a, b) => a - b).toString()
      ) {
        localStorage.setItem("stockControlProducts", JSON.stringify(responseArray));
      }

    }

  }, [responseArray, fetchFlag]);


  return (
    <div className="w-full h-full mt-[8rem]" style={{ background: "#fff" }} >
      <div
        className="productCard bg-white m-auto w-11/12 rounded-lg my-6 fixed z-10 left-0 right-0 py-4 top-4"
      >
        {stockControlData.length === 0 ? <Loading /> : ''}
        <Autocomplete
          disablePortal
          id="combo-box"
          options={uniqRes.map((item) => item.modelCode)}
          sx={{ width: 300 }}
          renderInput={(params) => <TextField {...params} label={`${t("EnterReferenceCode")}`} />}
          onInputChange={(event, newInputValue) => { setSelectedOption(newInputValue); }}
          value={selectedOption}
          className="m-auto"
          style={{ width: "100%" }}
        />

      </div>


      {filteredData.length > 0 ? (filteredData.map((product) => (
        <div key={product.id} style={{ display: searchData.find((item) => item.id === product.id) || searchData.length === 0 ? "block" : "none" }} >
          <SHStockControlCard
            key={product.name}
            productName={product.name}
            productBarcode={product.barcode}
            productBrand={product.brand}
            productColor={product.color}
            productRefNo={product.modelCode}
            productItemNo={product.itemCode}
            productPrice={product.finalPrice}
            productCategory={product.category}
            productDesc={product.description}
            customerDesc={product.descriptionToCustomer}
            productSize={product.size}
            productGender={product.gender}
            addedBy={product.addedBy}
            addedTime={product.addedTime}
            productCondition={product.condition}
            productDamagedInWh={product.damagedInWh}
            productDiscountRate={product.discountRate}
            productSystemPrice={product.systemPrice}
            productAge={product.age}
            id={product.id}
          />
        </div>
      ))) : <div className="z=[-20] text-center font-bold">Not Found...</div>}

    </div>
  );

}


export default SHStockControl;