import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import Backend from 'i18next-http-backend';

const i18nLocalStorage = {
    get: (key) => localStorage.getItem(key),
    set: (key, value) => localStorage.setItem(key, value),
    type: 'localStorage'
  };

i18n
  .use(initReactI18next)
  .use(Backend)
  .init({
    lng: i18nLocalStorage.get('selectedLanguage') || 'tr',
  });

  export default i18n;