import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import { Login } from "../pages/login/Login";
import { FedidLogin } from "../pages/login/FedidLogin";
import { SelectApp } from "../pages/SecondHand/SelectApp";
import "../App.css";
import { VideoBarcodeReader } from "../VideoBarcodeReader"
import { SHHomepage } from "../pages/SecondHand/SHHomePage";
import { SHApproval } from "../pages/SecondHand/SHApproval";
import { SHStockControl } from "../pages/SecondHand/SHStockControl";
import { SHPrintLabel } from "../pages/SecondHand/SHPrintLabel";
import { SHAddProduct } from "../pages/SecondHand/AddProduct/SHAddProduct";
import { ProductCondition } from "../pages/SecondHand/AddProduct/ProductCondition";
import { ProductPrice } from "../pages/SecondHand/AddProduct/ProductPrice";
import { AddPhoto } from "../pages/SecondHand/AddProduct/AddPhoto";
import { Layout } from "../pages/Layout";
import AddLabel from "../pages/SecondHand/AddProduct/AddLabel";
import BuyBackAddProductInformation from "../pages/SecondHand/AddProduct/BuyBackAddProductInformation";
import BuyBackAddProduct from "../pages/SecondHand/AddProduct/BuyBackAddProduct";
import Contract from "../pages/SecondHand/AddProduct/Contract";
import PrintContractLabel from "../pages/SecondHand/AddProduct/PrintContractLabel";
import AddVoucherNumber from "../pages/SecondHand/AddProduct/AddVoucherNumber";
import StoreSettings from "../pages/StoreSettings/StoreSettings";
import WriteOff from "../pages/SecondHand/RemoveProduct/WriteOff";


const MasterRouter = () => {

  return (

    <Router>

      <Layout />
      <Routes>

        <Route exact path="/" element={<Login />} />
        <Route exact path="/login" element={<FedidLogin />} />
        <Route exact path="/select-app" element={<SelectApp />} />
        <Route exact path="/reader" element={<VideoBarcodeReader />} />
        <Route exact path="/sh-homepage" element={<SHHomepage />} />
        <Route exact path="/sh-approval" element={<SHApproval />} />
        <Route exact path="/sh-print-alert" element={<SHPrintLabel />} />
        <Route exact path="/sh-stock-control" element={<SHStockControl />} />
        <Route exact path="/sh-store-settings" element={<StoreSettings />} />
        <Route exact path="/sh-stock-management" element={<WriteOff />} />
        <Route path="/sh-add-product/*" element={<SHAddProduct />} />
        <Route path="/sh-add-product/price" element={<ProductPrice />} />
        <Route path="/sh-add-product/condition" element={<ProductCondition />} />
        <Route path="/sh-add-product/add-photo" element={<AddPhoto />} />
        <Route path="/sh-add-product/add-label" element={<AddLabel />} />
        <Route path="/sh-add-product/add-information" element={<BuyBackAddProductInformation />} />
        <Route path="/sh-add-product/add-product-detail-buyback" element={<BuyBackAddProduct />} />
        <Route path="/sh-add-product/contract-buyback" element={<Contract />} />
        <Route path="/sh-add-product/contract-label-buyback" element={<PrintContractLabel />} />
        <Route path="/sh-add-product/voucher-buyback" element={<AddVoucherNumber />} />

      </Routes>
    </Router>

  );
};

export default MasterRouter;
