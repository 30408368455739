import React, { useEffect, useState, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { get, set } from "../../services/cache";
import config from "../../services/config";


export const SelectApp = () => {
  const redirect = useNavigate();

  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    // console.log("CALISIYO SELECT-APP");
    // console.log(get(config.FedidLogin.cacheKey,"keyyyyyy"))
    // remove(config.FedidLogin.cacheKey)
  }, []);

  return (
    <div >
      <div className="flex flex-col	text-center justify-center text-xl	absolute h-full w-full" style={{ backgroundColor: "#00537D" }}>
        <button
          className="bg-white border-1 rounded my-4 py-5 w-10/12 self-center font-bold  blue600 border-2 loginButton"
          onClick={() => {
            redirect("/sh-homepage");
            localStorage.setItem("typeOfApp", "buyBack");
          }}

        >
          {t("BuyBack")}
        </button>
        <button
          className="bg-white border-1 rounded my-4 py-5 w-10/12 self-center font-bold  blue600 border-2 loginButton relative"
          onClick={() => {
            redirect("/sh-homepage");
            localStorage.setItem("typeOfApp", "secondLife");
          }}
        >
          {t("SecondLife")}
          <span className="absolute bottom-0 w-full left-0 text-base text-gray-700"> ({t("AddProductDisabled")})</span>
        </button>
      </div>
    </div>
  );
};
