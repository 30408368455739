import React, { useEffect, useState } from "react";
import { get, set } from "../../../services/cache";
import config from "../../../services/config";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import Dialog from '@mui/material/Dialog';
import DialogContent from '@mui/material/DialogContent';
import Button from '@mui/material/Button';
import LoadingButton from '@mui/lab/LoadingButton';
import Snackbar from '@mui/material/Snackbar';
import Alert from '@mui/material/Alert';
import Barcode from 'react-barcode';

export const PrintContractLabel = (props) => {


    const userAccessToken = get("fedidLoginAccessToken");
    const redirect = useNavigate();
    const [contractError, setContractError] = useState(false);
    const [snackOpen, setSnackOpen] = useState(false);
    const [loading, setLoading] = useState(false);
    const [open, setOpen] = useState(false);

    useEffect(() => {
        if (!userAccessToken?.data?.access_token) {
            redirect("/");
            window.location.reload();
        }
    })

    useEffect(() => {
        let backButton;
        backButton = document.getElementById("backButton");

        backButton.addEventListener('click', goBack);

    }, [])

    function goBack() {
        props.changeShowCard(true);
    }

    const handleClose = () => {
        setOpen(false);
    };

    const printContractTab = () => {
        setOpen(true);
    }

    const printContract = () => {
        setLoading(true)
        fetch(`${config.baseUrl}/v1/products/print/contract/${get("AddProductData").data?.id}`, {
            method: "POST",
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
        })
            .then((response) => response.json())
            .then((res) => { setSnackOpen(true); setLoading(false) })
            .catch((error) => { console.log(error); setContractError(true); setSnackOpen(true); setLoading(false) });
    }


    const handleCloseSnack = () => {
        setSnackOpen(false)
    };

    return (
        <div className="w-full items-center flex justify-center mt-[3rem]">
            <div className="w-full h-full flex justify-center flex-col productCard items-center" style={{ background: "#fff" }} >

                <img src="https://contents.mediadecathlon.com/s1054152/k$a5547205eb1a33342e1e0fcaba041c23/Group%203081.png" alt="" />

                <span className="text-xl p-8 text-center font-bold blue600">
                    {t("PrintLabelContract")}
                </span>

                <Dialog
                    onClose={handleClose}
                    aria-labelledby="customized-dialog-title"
                    open={open}
                >
                    <DialogContent className="w-auto">
                        <div className="w-full text-center p-4 text-xl">
                            {t("ContractBarcodeNumber")}
                        </div>
                         <Barcode className="w-[-webkit-fill-available]" margin={0} textMargin={25} width={2} height={100} value={get("BuyBackContract").data.contractNumber} />
                        <div className="flex flex-col w-full mt-4 gap-4">
                            <LoadingButton
                                variant="contained"
                                className={`!font-bold !py-2.5 ${loading ? "bg-white" : "!bg-[#00537D]"}`}
                                onClick={printContract}
                                loading={loading}
                                disabled={loading}
                            >{t("PrintBarcode")}</LoadingButton>
                            <Button variant="contained" className="!font-bold !py-2.5 !bg-[#007DBC]" onClick={() => setOpen(false)}>{t("Next")}</Button>
                        </div>
                    </DialogContent>
                </Dialog>

                <Snackbar
                    open={snackOpen}
                    autoHideDuration={3000}
                    onClose={handleCloseSnack}
                    anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                >
                    <Alert
                        onClose={handleCloseSnack}
                        severity={contractError ? "error" : "success"}
                        variant="filled"
                        sx={{ width: '100%' }}
                        className="!py-2.5"
                    >
                        {contractError ? t("PrintContractError") : t("PrintContractSuccess")}
                    </Alert>
                </Snackbar>

                <div className="addProductStickyButtonsBg !h-[auto] !bottom-[75px] z-10">
                    <button className="w-11/12 addProductStickyButtons z-10 !shadow-none !bg-[#00537D]" onClick={printContractTab}>{t("Print")}</button>
                </div>

                <div className="addProductStickyButtonsBg">
                    <button className="w-11/12 addProductStickyButtons z-10" onClick={() => { redirect('/sh-add-product/voucher-buyback') }}>{t("Next")}</button>
                </div>
            </div>
        </div>
    );

}


export default PrintContractLabel;