import React, { useEffect, useState } from "react";
import { get, set } from "../../../services/cache";
import config from "../../../services/config";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const ProductCondition = (props) => {


    const userAccessToken = get("fedidLoginAccessToken");

    const redirect = useNavigate();
    let typeOfApp = localStorage.getItem("typeOfApp");

    const [selectedCondition, setSelectedCondition] = useState(get("addCondition")?.data ? get("addCondition")?.data : '30');


    useEffect(() => {
        if (!userAccessToken?.data?.access_token) {
            redirect("/");
            window.location.reload();
        }
    })

    useEffect(() => {
        let backButton;
        backButton = document.getElementById("backButton");

        backButton.addEventListener('click', goBack);

        if (!userAccessToken?.data?.access_token) {
            redirect("/");
        }
        if (!get("addCondition") && !get("conditionInfo")) {
            set({ key: "addCondition", data: 30 })
            set({ key: "conditionInfo", data: "Good" })
        }
    }, [])

    function goBack() {
        props.changeShowCard(true);
    }

    const updateCondition = () => {
        redirect("/sh-add-product")
    }

    const handleOptionChange = (e) => {
        setSelectedCondition(e.target.value)
        const numericValue = parseInt(e.target.value);
        set({ key: "addCondition", data: numericValue })
    }

    const secondLifeCondition = () => {
        updateCondition();
        redirect('/sh-add-product')
    }

    const buyBackCondition = () => {
        redirect('/sh-add-product')
    }


    return (
        <div className="w-full items-center flex justify-center mt-[3rem]">
            <div className="w-full h-full flex justify-center flex-col productCard items-center" style={{ background: "#fff" }} >

                <ul className="w-11/12 flex flex-col self-center gap-4 mt-6 mb-24">
                    <li>
                        <input type="radio" value="40" checked={selectedCondition === '40'} onChange={(e) => { handleOptionChange(e); set({ key: "conditionInfo", data: "BrandNew" }) }} id="brandNewOption" className="hidden peer" required />
                        <label htmlFor="brandNewOption" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-slate-100 border border-gray-200 rounded-lg cursor-pointer   peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                            <div className="block">
                                <div className="w-full text-lg font-bold">{t("BrandNew")}</div>
                                <div className="w-full text-[#687787]">{t("BrandNewDesc")} <br /> {t("BrandNewDescComment")}</div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input type="radio" id="goodConditionOption" value="30" checked={selectedCondition === '30'} onChange={(e) => { handleOptionChange(e); set({ key: "conditionInfo", data: "Good" }) }} className="hidden peer" />
                        <label htmlFor="goodConditionOption" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-slate-100 border border-gray-200 rounded-lg cursor-pointer   peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                            <div className="block">
                                <div className="w-full text-lg font-bold">{t("GoodCondition")}</div>
                                <div className="w-full text-[#687787]">{t("GoodConditionDesc")} <br /> {t("GoodConditionDescComment")}</div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input type="radio" id="passableOption" value="20" checked={selectedCondition === '20'} onChange={(e) => { handleOptionChange(e); set({ key: "conditionInfo", data: "Passable" }) }} className="hidden peer" />
                        <label htmlFor="passableOption" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-slate-100 border border-gray-200 rounded-lg cursor-pointer   peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                            <div className="block">
                                <div className="w-full text-lg font-bold">{t("NotBad")}</div>
                                <div className="w-full text-[#687787]"> {t("NotBadDesc")} <br /> {t("NotBadDescComment")} </div>
                            </div>
                        </label>
                    </li>
                    <li>
                        <input type="radio" id="notGoodOption" value="10" checked={selectedCondition === '10'} onChange={(e) => { handleOptionChange(e); set({ key: "conditionInfo", data: "NotGood" }) }} className="hidden peer" />
                        <label htmlFor="notGoodOption" className="inline-flex items-center justify-between w-full p-5 text-gray-500 bg-slate-100 border border-gray-200 rounded-lg cursor-pointer  peer-checked:border-blue-600 peer-checked:text-blue-600 hover:text-gray-600 hover:bg-gray-100 ">
                            <div className="block">
                                <div className="w-full text-lg font-bold">{t("NotGood")}</div>
                                <div className="w-full text-[#687787]">{t("NotGoodDesc")} <br /> {t("NotGoodDescComment")}</div>
                            </div>
                        </label>
                    </li>
                </ul>

                <div className="addProductStickyButtonsBg">
                    <button className="w-11/12 addProductStickyButtons z-10" onClick={() => { typeOfApp === "secondLife" ? secondLifeCondition() : buyBackCondition(); }}>{t("Save")}</button>
                </div>
            </div>
        </div>
    );

}


export default ProductCondition;