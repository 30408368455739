import React, { useEffect, useState } from "react";
import generateHash from "../../services/generateHash";
import config from "../../services/config";
import { dateAdd } from "../../services/utils";
import { set } from "../../services/cache";
import { t } from "i18next"

export const Login = () => {
  const [fedidUrl, setFedidUrl] = useState("");

  useEffect(() => {
    const crypto = generateHash();

    set({
      key: config.FedidCodeVerifier.cacheKey,
      data: crypto,
      expires: dateAdd(new Date(), "second", 7199).toISOString(),
    });

    let url = `${process.env.REACT_APP_LOGIN_URL}&code_challenge=${crypto}&code_challenge_method=plain`;

    setFedidUrl(url);
  }, []);

  return (
    <div className="text-center flex justify-center	login">
      <div className="flex flex-col	text-center justify-center text-xl	absolute h-full w-full" style={{background: '#00537D'}}>
        {/* <Box> */}
        <div className="self-center">
          {/* <span className="text-6xl	">LOGO</span> */}
          <svg
            width="118"
            height="110"
            viewBox="0 0 118 110"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
              stroke="white"
              strokeWidth="3"
            />
            <path
              d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
              stroke="white"
              strokeWidth="3"
            />
            <path
              d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
              stroke="white"
              strokeWidth="3"
            />
          </svg>

          <span className="text-4xl font-bold text-white">D-Care</span>
        </div>
        <a
          className="bg-white border-2 rounded my-9 py-3 w-9/12 self-center font-bold blue600 loginButton"
          variant="contained"
          href={fedidUrl}
        >
          {t("Login")}
        </a>

        {/* </Box> */}
      </div>
    </div>
  );
};
