import React, { useEffect, useState } from "react";
import { get, set } from "../../../services/cache";
import config from "../../../services/config";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";

export const Contract = (props) => {


    const userAccessToken = get("fedidLoginAccessToken");
    const redirect = useNavigate();
    let typeOfApp = localStorage.getItem("typeOfApp");


    useEffect(() => {
        if (!userAccessToken?.data?.access_token) {
            redirect("/");
            window.location.reload();
        }
    })

    useEffect(() => {
        let backButton;
        backButton = document.getElementById("backButton");

        backButton.addEventListener('click', goBack);

    }, [])

    function goBack() {
        props.changeShowCard(true);
    }


    return (
        <div className="w-full items-center flex justify-center mt-[3rem]">
            <div className="w-full h-full flex justify-center flex-col productCard items-center" style={{ background: "#fff" }} >

                <img src="https://contents.mediadecathlon.com/s1054151/k$21867f346fa0fc020f1ef92cc6873d04/Group%203085.png" alt="" />

                <span className="text-xl p-8 text-center font-bold blue600">
                    {t("SignContract")}
                </span>

                <div className="addProductStickyButtonsBg">
                    <button className="w-11/12 addProductStickyButtons z-10" onClick={() => { redirect('/sh-add-product/contract-label-buyback') }}>{t("ContractSigned")}</button>
                </div>
            </div>
        </div>
    );

}


export default Contract;