function fromByteToHex(byte) {
  return byte.toString(16).padStart(2, "0");
}

function generateHash(size = 47) {
  const values = new Uint8Array(size / 2);
  window.crypto.getRandomValues(values);
  return Array.from(values, fromByteToHex).join("");
}

export default generateHash;
