import React, { useEffect, useState } from "react";
import { get } from "../../services/cache";
import { useNavigate } from "react-router-dom";
import { t } from "i18next";
import TextField from '@mui/material/TextField';
import config from "../../services/config";

export const StoreSettings = (props) => {


    const userAccessToken = get("fedidLoginAccessToken");
    const redirect = useNavigate();

    const [printerId, setPrinterId] = useState('')
    const [open, setOpen] = useState(false);
    const [success, setSuccess] = useState(null);
    const [existPrinter, setExistPrinter] = useState(null);


    useEffect(() => {
        if (!userAccessToken?.data?.access_token) {
            redirect("/");
            window.location.reload();
        }
    })

    useEffect(() => {
        fetch(`${config.baseUrl}/v1/settings/printer`, {
            method: "GET",
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
            },
            credentials: "same-origin",
        })
            .then((response) => response.json())
            .then((res) => {
                setPrinterId(res.data)
            })
            .catch((error) => console.log(error));
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    const handleChangeSerialNumber = () => {
        fetch(`${config.baseUrl}/v1/settings/printer?serialNumber=${printerId}`, {
            method: "PATCH",
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
                'Content-Type': 'application/json'
            },
            redirect: "follow",
            credentials: "same-origin",
        })
            .then((response) => {
                response.json();
                if (response.status === 200) { setSuccess(true) }
                else if (response.status === 400) {
                    setExistPrinter(true);
                }
                else { setSuccess(false) }
            })
            .then((res) => {
                setOpen(true);
            })
            .catch((error) => {
                console.error(error);
                setOpen(true);
                setSuccess(false);
            });
    }

    const printerIdChange = (e) => {
        let { value } = e.target;
        value = value.toUpperCase();
    
        const regexPrefix = /^[XD]?[XB]?[ZQJ]?[KL]?J?$/;
        const regexFull = /^[XD][XB][ZQJ][KL]?J?\d{0,9}$/;
        const isDBJ = value.startsWith('DBJ') && value.length > 3 && /[A-Za-z]/.test(value[3]);
    
        if ((value === '' || (regexPrefix.test(value) || regexFull.test(value))) && !isDBJ) {
          if (value.length <= 14) {
            setPrinterId(value);
          }
        }
      };
    
    return (
        <div className="w-full items-center flex justify-center mt-[3rem]">
            <div className="w-full h-full flex justify-center flex-col productCard items-center gap-8" style={{ background: "#fff" }} >
                <span className="w-1/4 mt-10">
                    <svg width="85" height="85" viewBox="0 0 85 85" fill="none" xmlns="http://www.w3.org/2000/svg" className="w-full h-full">
                        <path fillRule="evenodd" clipRule="evenodd" d="M18.3013 0.626953H66.6971V23.352H77.4284C79.4081 23.352 81.3032 24.0256 82.7112 25.4337C84.1192 26.8417 84.7929 28.7368 84.7929 30.7165V71.7478H66.6971V84.3728H18.3013V71.7478H0.626266V30.7165C0.626266 28.7368 1.29996 26.8417 2.708 25.4337C4.11605 24.0256 6.0111 23.352 7.99085 23.352H18.3013V0.626953ZM24.6138 23.352H60.3846V6.93945H24.6138V23.352ZM18.3013 65.4353V52.8103H66.6971V65.4353H78.4804V30.7165C78.4804 30.1713 78.3125 29.9622 78.2476 29.8973C78.1827 29.8324 77.9736 29.6645 77.4284 29.6645H7.99085C7.4456 29.6645 7.23649 29.8324 7.17161 29.8973C7.10674 29.9622 6.93877 30.1713 6.93877 30.7165V65.4353H18.3013ZM25.6658 34.2936V40.6061H10.9367V34.2936H25.6658ZM24.6138 59.1228V78.0603H60.3846V59.1228H24.6138Z" fill="#101010" />
                    </svg>
                </span>

                <div className="flex flex-col w-100% bg-[#BEDEEF] p-4 w-11/12 items-center">
                    <span className="font-bold mb-3">{t("PrinterSerialNumbers")}</span>
                    <span> <span className="font-bold"> ZQ620: </span> XXZKJ123456789</span>
                    <span> <span className="font-bold"> QLN320: </span> XXQLJ123456789</span>
                    <span> <span className="font-bold"> ZD621R: </span> DBJ123456789</span>
                </div>

                <div className="font-bold text-[#00689D]">
                    {localStorage.getItem("selectedLanguage")?.data === 'tr' || !localStorage.getItem("selectedLanguage")
                        ?
                        <>{get("claims").data.storeName} mağazası için kullanımda olacaktır. </>
                        :
                        <> Will be in use for {get("claims").data.storeName} store. </>
                    }
                </div>

                <TextField id="outlined-basic" label={t("EnterPrinterSerialNumber")} variant="outlined" className="w-11/12" value={printerId} onChange={printerIdChange} />

                {open ? (
                    <>
                        <div className="justify-center items-center flex overflow-x-hidden overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
                            <div className="relative w-11/12 my-6 mx-auto md:w-[30%]">
                                <div className="border-0 rounded-lg shadow-lg relative flex flex-col w-full bg-white outline-none focus:outline-none">
                                    <div className="relative p-6 px-14 flex-auto">
                                        <div className="my-4 text-blueGray-500 text-lg leading-relaxed flex flex-col text-center items-center gap-6" >
                                            {success !== null && (success !== null && success ?
                                                <>
                                                    <span className="bg-[#E7F3F9] p-8 rounded-full">
                                                        <svg className="w-full" width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M23.579 13.0252H34.2481C35.1324 13.0252 35.9804 13.3765 36.6057 14.0018C37.231 14.627 37.5822 15.4751 37.5822 16.3593V19.8668C37.5827 20.3025 37.4977 20.7341 37.3322 21.1371L32.1727 33.6649C32.0468 33.9704 31.833 34.2315 31.5585 34.4152C31.2839 34.599 30.961 34.697 30.6306 34.6968H2.57427C2.13214 34.6968 1.70812 34.5212 1.39549 34.2086C1.08286 33.8959 0.907227 33.4719 0.907227 33.0298V16.3593C0.907227 15.9172 1.08286 15.4932 1.39549 15.1806C1.70812 14.8679 2.13214 14.6923 2.57427 14.6923H8.37892C8.64581 14.6924 8.90881 14.6284 9.14582 14.5056C9.38283 14.3829 9.5869 14.2051 9.7409 13.9871L18.8313 1.10588C18.9462 0.943028 19.1157 0.826748 19.309 0.778119C19.5023 0.729491 19.7066 0.751735 19.8849 0.840816L22.9089 2.35283C23.7599 2.77819 24.4398 3.48194 24.8355 4.34714C25.2313 5.21234 25.3191 6.1869 25.0844 7.10891L23.579 13.0252ZM10.9095 17.3396V31.3627H29.5137L34.2481 19.8668V16.3593H23.579C23.0713 16.3593 22.5702 16.2432 22.1141 16.02C21.658 15.7968 21.2589 15.4724 20.9472 15.0715C20.6356 14.6706 20.4196 14.2038 20.3158 13.7068C20.212 13.2097 20.2231 12.6955 20.3483 12.2034L21.8536 6.28872C21.9008 6.10422 21.8833 5.90916 21.8041 5.73598C21.725 5.56279 21.5889 5.42194 21.4186 5.33684L20.3166 4.78671L12.4649 15.9092C12.0481 16.4994 11.5146 16.9828 10.9095 17.3396ZM7.57541 18.0264H4.24132V31.3627H7.57541V18.0264Z" fill="#007DBC" />
                                                        </svg>
                                                    </span>
                                                    <div className="flex flex-col text-[#004876]">
                                                        <span>"{printerId}"</span>
                                                        <span>{t("Registered")}</span>
                                                    </div>
                                                    <button className="bgBlue500 w-full rounded text-white p-2.5 font-bold" onClick={() => { setOpen(false); setSuccess(null); redirect('/sh-homepage') }}>{t("Okay")}</button>
                                                </>
                                                :
                                                <>
                                                    <span className="bg-[#E3262F] p-8 rounded-full">
                                                        <svg width="38" height="35" viewBox="0 0 38 35" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M14.9104 22.3493H4.24132C3.35706 22.3493 2.50902 21.9981 1.88376 21.3728C1.2585 20.7475 0.907228 19.8995 0.907228 19.0152V15.5078C0.906779 15.0721 0.991732 14.6405 1.15729 14.2375L6.31846 1.7113C6.44395 1.40582 6.65736 1.1445 6.9316 0.960486C7.20584 0.776472 7.52855 0.67806 7.85881 0.677734H35.9152C36.3573 0.677734 36.7813 0.853369 37.094 1.166C37.4066 1.47863 37.5822 1.90265 37.5822 2.34478V19.0152C37.5822 19.4574 37.4066 19.8814 37.094 20.194C36.7813 20.5066 36.3573 20.6823 35.9152 20.6823H30.1105C29.8436 20.6822 29.5806 20.7462 29.3436 20.8689C29.1066 20.9916 28.9025 21.1695 28.7486 21.3874L19.6582 34.267C19.5432 34.4299 19.3738 34.5462 19.1805 34.5948C18.9872 34.6434 18.7829 34.6212 18.6046 34.5321L15.5806 33.0184C14.7295 32.593 14.0497 31.8893 13.6539 31.0241C13.2582 30.1589 13.1703 29.1843 13.4051 28.2623L14.9104 22.3493ZM27.58 18.035V4.01182H8.97573L4.24132 15.5078V19.0152H14.9104C15.4182 19.0153 15.9193 19.1314 16.3754 19.3545C16.8315 19.5777 17.2306 19.9021 17.5422 20.303C17.8539 20.7039 18.0698 21.1707 18.1736 21.6678C18.2774 22.1649 18.2663 22.6791 18.1411 23.1712L16.6358 29.0859C16.5887 29.2703 16.6062 29.4654 16.6853 29.6386C16.7645 29.8118 16.9006 29.9526 17.0709 30.0377L18.1728 30.5879L26.0246 19.4653C26.4414 18.8752 26.9748 18.3918 27.58 18.035ZM30.914 17.3482H34.2481V4.01182H30.914V17.3482Z" fill="white" />
                                                        </svg>
                                                    </span>
                                                    <div className="flex flex-col text-[#004876]">
                                                        <span>"{printerId}"</span>
                                                        <span>{t("WrongSerialNumber")}</span>
                                                    </div>
                                                    <button className="bg-[#012B49] w-full rounded text-white p-2.5 font-bold" onClick={() => { setOpen(false); setSuccess(null) }}>{t("TryAgain")}</button>
                                                </>)
                                            }
                                            {existPrinter &&
                                                <>
                                                    <span className="bg-[#E7F3F9] p-8 rounded-full">
                                                        <svg width="36" height="32" viewBox="0 0 36 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                            <path fillRule="evenodd" clipRule="evenodd" d="M35.5299 29.011L19.6532 1.51099C19.3555 0.995367 18.8053 0.677734 18.2099 0.677734C17.6145 0.677734 17.0643 0.995367 16.7666 1.51099L0.889917 29.011C0.592199 29.5267 0.592195 30.162 0.889906 30.6776C1.18762 31.1933 1.73782 31.511 2.33325 31.511H34.0866C34.682 31.511 35.2322 31.1933 35.5299 30.6776C35.8276 30.162 35.8276 29.5267 35.5299 29.011ZM31.1999 28.1776H5.21995L18.2099 5.67762L31.1999 28.1776ZM16.5433 23.1776H19.8767V26.511H16.5433V23.1776ZM19.8767 11.511H16.5433V19.8443H19.8767V11.511Z" fill="#00689D" />
                                                        </svg>

                                                    </span>
                                                    <div className="flex flex-col text-[#004876]">
                                                        <span>"{printerId}"</span>
                                                        <span>{t("PrinterAlreadyRegistered")}</span>
                                                    </div>
                                                    <button className="bg-[#012B49] w-full rounded text-white p-2.5 font-bold" onClick={() => { setOpen(false); setExistPrinter(false) }}>{t("TryAgain")}</button>
                                                </>}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="opacity-25 fixed inset-0 z-40 bg-black"></div>
                    </>
                ) : null}

                <div className="addProductStickyButtonsBg">
                    <button className={`w-11/12 addProductStickyButtons z-10 ${!printerId ? 'opacity-40' : ''}`} disabled={!printerId} onClick={handleChangeSerialNumber}>{t("Save")}</button>
                </div>
            </div>
        </div>
    );

}


export default StoreSettings;