export function getHashCode(s) {
    let hash = 0;
    if (s.length === 0) {
      return hash;
    }
  
    for (let i = 0; i < s.length; i++) {
      const chr = s.charCodeAt(i);
      hash = ((hash << 5) - hash) + chr;
      hash |= 0;
    }
    return hash;
  }

  export function dateAdd(date, interval, units) {
    let ret = new Date(date.toString());
    switch (interval.toLowerCase()) {
      case "year": ret.setFullYear(ret.getFullYear() + units); break;
      case "quarter": ret.setMonth(ret.getMonth() + 3 * units); break;
      case "month": ret.setMonth(ret.getMonth() + units); break;
      case "week": ret.setDate(ret.getDate() + 7 * units); break;
      case "day": ret.setDate(ret.getDate() + units); break;
      case "hour": ret.setTime(ret.getTime() + units * 3600000); break;
      case "minute": ret.setTime(ret.getTime() + units * 60000); break;
      case "second": ret.setTime(ret.getTime() + units * 1000); break;
      default: ret = date; break;
    }
    return ret;
  }