import React, { useEffect, useState, useRef } from "react";
import { get } from "../../services/cache";
import config from "../../services/config";
import QRCode from "react-qr-code";
import { t } from "i18next"

export const SHPrintLabelCard = (props) => {

    const userAccessToken = get("fedidLoginAccessToken");
    let typeOfApp = localStorage.getItem("typeOfApp");


    let data = JSON.parse(localStorage.getItem("printLabelProducts")) || []
    let target = data?.findIndex(obj => obj?.referenceCode === props?.productRefNo)


    const printRef = useRef(null);

    const [isExpanded, setIsExpanded] = useState(false);
    const [printPopUp, setPrintPopUp] = useState(false);
    const [productInfo, setProductInfo] = useState();
    const [size, setSize] = useState();


    const conditions = {
        10: t("NotGood"),
        20: t("NotBad"),
        30: t("GoodCondition"),
        40: t("BrandNew"),
    };


    const PrintPopUpScreen = () => {
        setPrintPopUp(!printPopUp);
        document.body.style.overflowY = printPopUp ? 'initial' : 'hidden';
    }

    const updateStatus = () => {

        fetch(`${config.baseUrl}/v1/products/${props?.id}/status?statusCode=4`, {
            method: 'PATCH',
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                'x-api-key': config.apiKey,
                "Content-Type": "application/json"
            },
            credentials: 'same-origin',
        })
            .then((response) => {
                if (!response.ok) {
                    alert('Something went wrong. Try again');
                }
            })
            .catch((error) => {
                console.error('Hata:', error);
            });

        fetch(`${config.baseUrl}/v1/products/print?ids=${props?.id}`, {
            body: JSON.stringify([props?.id]),
            method: 'POST',
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                'x-api-key': config.apiKey,
                "Content-Type": "application/json"
            },
            credentials: 'same-origin'
        })
            .then((response) => {
                if (response.ok) {
                    window.location.reload();
                    alert('Printing successful!');
                } else {
                    alert('Something went wrong. Try again');
                }
            })
            .catch((error) => {
                console.error('Hata:', error);
            });

    }

    // useEffect(() => {
    //     let barcode;
    //     if (printPopUp) {
    //         barcode = document?.querySelector(".barcode > svg");
    //         barcode.style.width = "300px";
    //     }
    // })

    useEffect(() => {

        if (localStorage.getItem("printLabelProducts")) {
            setProductInfo(data[target]);
        }

        const filteredItem = JSON.parse(localStorage.getItem("printLabelProducts"))?.find((item) =>
            item?.itemCodes?.some((code) => code?.itemCode === props?.productItemNo)
        );
        if (filteredItem) {
            setSize(filteredItem.itemCodes.find((code) => code.itemCode === props.productItemNo).size);
        }
    }, [localStorage.getItem("printLabelProducts")])


    return (
        <>
            <div className="productCard bg-white w-11/12 rounded-lg my-6 border-2 px-4 selectList" >
                <div className="flex justify-center items-center border-b">
                    <span className="mr-1"><svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path fillRule="evenodd" clipRule="evenodd" d="M6.99967 13.6673C3.31767 13.6673 0.333008 10.6827 0.333008 7.00065C0.333008 3.31865 3.31767 0.333984 6.99967 0.333984C10.6817 0.333984 13.6663 3.31865 13.6663 7.00065C13.6663 10.6827 10.6817 13.6673 6.99967 13.6673ZM6.99967 12.334C9.94519 12.334 12.333 9.94617 12.333 7.00065C12.333 4.05513 9.94519 1.66732 6.99967 1.66732C4.05416 1.66732 1.66634 4.05513 1.66634 7.00065C1.66634 9.94617 4.05416 12.334 6.99967 12.334ZM10.333 7.00065H7.66634V3.66732H6.33301V8.33398H10.333V7.00065Z" fill="#E3262F" />
                    </svg>
                    </span>
                    <span className="text-red-600 font-bold text-center text-sm py-2">{t("WaitingtoPrint")} </span>
                </div>
                <div className="flex place-content-between mt-4 rounded border cursor-pointer" onClick={() => { setIsExpanded(!isExpanded) }}>
                    <div className="w-1/3 relative">
                        <div className="max-w-full h-auto p-2 mb-5">
                            {productInfo?.mainImageUrl ?
                                <img src={productInfo?.mainImageUrl} alt="" />
                                :
                                <svg
                                    width="118"
                                    height="110"
                                    viewBox="0 0 118 110"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                    className="max-w-full h-auto m-auto"
                                >
                                    <path
                                        d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                    <path
                                        d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
                                        stroke="black"
                                        strokeWidth="3"
                                    />
                                </svg>
                            }
                        </div>
                        <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">{props.productRefNo}</span>
                    </div>
                    <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5 relative">
                        <div className="flex justify-between items-center py-1.5">
                            <span className="font-bold">{productInfo?.brand ? productInfo?.brand : t("Loading")} </span>
                            <span className="font-bold p-1.5 rounded absolute right-0" style={{ backgroundColor: '#FFEA28' }}> {props?.productPrice} ₺</span>
                        </div>
                        <span>
                            {productInfo ? productInfo.title : t("Loading")}
                        </span>
                        <span className="mt-2"> <span className="font-bold">{t("Size")} </span>: {size ? size : t("NotFound")}</span>

                    </div>

                </div>

                <div className="flex flex-col text-center text-xs text-gray-500 p-3">
                    <span>{t("AddedDate")}: {props.addedTime}</span>
                    <span>{t("SubmittedBy")}: {props.addedBy}</span>
                </div>
                <div className="flex gap-4 text-sm pb-3 font-bold">
                    <button className="w-full p-1.5 rounded text-white" style={{ backgroundColor: "#00537D" }} onClick={() => { PrintPopUpScreen(); }}>{t("Barcode")}</button>
                </div>
            </div>

            {printPopUp && (
                <>
                    <div className="fixed h-full w-full flex justify-center top-0 z-20">
                        <div className="bg-white px-1 py-4 w-11/12 self-center items-center flex rounded relative justify-center flex-col printPopUpParent">
                            <div className="flex flex-col w-10/12 items-center barcode" ref={printRef}>
                                <div className="flex place-content-between mt-4 rounded border cursor-pointer w-full">
                                    <div className="w-1/3 relative">
                                        <div className="max-w-full h-auto p-2 mb-5">
                                            {productInfo?.mainImageUrl ?
                                                <img src={productInfo?.mainImageUrl} alt="" />
                                                :
                                                <svg
                                                    width="118"
                                                    height="110"
                                                    viewBox="0 0 118 110"
                                                    fill="none"
                                                    xmlns="http://www.w3.org/2000/svg"
                                                    className="max-w-full h-auto m-auto"
                                                >
                                                    <path
                                                        d="M36.028 34.2192H34.528V35.7192V39.816L10.2291 21.1246L33.9068 3.03378V7.76551V9.26551H35.4068H55.2849H55.3162L55.3474 9.2642C57.7213 9.16529 64.4248 9.32371 71.7445 10.7857C82.4238 13.2058 90.2727 18.9907 95.6645 25.0343L94.8137 26.0708L79.148 45.1544C74.2529 37.1395 65.407 34.5315 61.2985 34.2234L61.2425 34.2192H61.1863H36.028Z"
                                                        stroke="black"
                                                        strokeWidth="3"
                                                    />
                                                    <path
                                                        d="M7.45432 82.8743H8.94614L8.9543 81.3825L9.24839 27.5626L34.2186 46.7064V81.3743V82.8743H35.7186H39.816L21.1379 107.156L2.99341 82.8743H7.45432Z"
                                                        stroke="black"
                                                        strokeWidth="3"
                                                    />
                                                    <path
                                                        d="M81.6868 56.891H77.6697L95.9657 34.0873L113.997 56.891H109.641H108.141V58.391C108.141 65.322 106.323 74.11 103.311 80.7414C92.95 102.413 71.6803 108.037 62.4214 108.139H27.8675L47.0239 82.8751H61.8086H61.8332L61.8578 82.8743C70.0209 82.6067 75.3296 78.386 78.5792 73.2997C81.7902 68.2737 83.022 62.3782 83.1855 58.4534L83.2506 56.891H81.6868Z"
                                                        stroke="black"
                                                        strokeWidth="3"
                                                    />
                                                </svg>
                                            }
                                        </div>
                                        <span className="text-xs ml-2 text-gray bg-gray-100 p-1 rounded absolute bottom-1">{props.productRefNo}</span>
                                    </div>
                                    <div className="flex flex-col text-sm w-2/3 bg-gray-50 p-2.5 relative">
                                        <div className="flex justify-between items-center py-1.5">
                                            <span className="font-bold">{productInfo?.brand ? productInfo?.brand : t("Loading")} </span>
                                            <span className="font-bold p-1.5 rounded absolute right-0" style={{ backgroundColor: '#FFEA28' }}> {props?.productPrice} ₺</span>
                                        </div>
                                        <span>
                                            {productInfo ? productInfo.title : t("NotFound")}
                                        </span>
                                        <span className="mt-2"> <span className="font-bold">{t("Size")} </span>: {size ? size : t("NotFound")}</span>
                                    </div>
                                </div>
                                {/* <Barcode value={props.productBarcode} /> */}
                                <div className="barcode my-2 border p-2 rounded w-full">
                                    <h1 className="text-sm font-bold">
                                        {productInfo?.title}
                                    </h1>
                                    <div className="flex items-start mt-1 justify-between">
                                        <div className="flex">
                                            <QRCode
                                                style={{ width: "40%", height: "100%" }}
                                                value={props.productBarcode}
                                            />
                                            <div className="flex ml-2">
                                                <div className="flex flex-col justify-between whitespace-nowrap">
                                                    <div className="">
                                                        <h2 className="text-sm font-semibold underline underline-offset-4 ">{typeOfApp === "secondLife" ? "İkinci Şans Ürünü" : "Buyback Ürünü"}</h2>
                                                        <p className="text-xs">Ürünün Durumu: {conditions[props.productCondition]} </p>
                                                        <p className="text-xs">Sıfır ürün fiyatı: {props.productSystemPrice} TL</p>
                                                        <p className="text-xs">Yüzdelik Fark: {props.productDiscountRate}%</p>
                                                    </div>
                                                    <div className="mt-1">
                                                        <p className="text-xs font-semibold">Ürünün İkinci Şans Fiyatı:</p>
                                                        <p className="text-xs font-bold">{props.productPrice} TL (*KDV Dahil)</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="flex  ml-1">
                                            <p className="text-xs font-bold border-t writing-vertical rotate-180">Ref No: {props?.productRefNo}</p>
                                            <p className="text-xs font-bold border-t writing-vertical rotate-180">Ulke: PRC</p>
                                        </div>
                                    </div>

                                </div>
                            </div>
                            <span className="absolute top-6 right-6" onClick={() => PrintPopUpScreen()}>
                                <svg
                                    width="12"
                                    height="12"
                                    viewBox="0 0 12 12"
                                    fill="none"
                                    xmlns="http://www.w3.org/2000/svg"
                                >
                                    <path
                                        d="M6.00011 4.875L10.1251 0.75L11.3034 1.92833L7.17844 6.05333L11.3034 10.1783L10.1251 11.3567L6.00011 7.23167L1.87511 11.3567L0.696777 10.1783L4.82178 6.05333L0.696777 1.92833L1.87511 0.75L6.00011 4.875Z"
                                        fill="#001018"
                                    />
                                </svg>
                            </span>
                            <button className="w-10/12 p-1.5 rounded text-white font-bold" style={{ backgroundColor: "#00537D" }} onClick={() => { PrintPopUpScreen(); updateStatus(); }}>{t("Print")}</button>

                        </div>
                    </div>

                    <div className={` ${printPopUp ? "printPopUp" : ""} `} />
                </>
            )}
        </>

    );
};
