import React, { useEffect, useState } from "react";
import { get, set } from "../../../services/cache";
import config from "../../../services/config";
import { useLocation, useNavigate } from "react-router-dom";
import { t } from "i18next";

export const AddLabel = (props) => {


    const location = useLocation();
    const userAccessToken = get("fedidLoginAccessToken");
    const [sendProduct, setSendProduct] = useState();
    const [productDesc, setProductDesc] = useState('')
    const [isChecked, setIsChecked] = useState(false)

    const redirect = useNavigate();

    const handleChange = (event) => {
        setProductDesc(event.target.value);
    };
    const handleCustomerDescChange = (event) => {
        setProductDesc(event.target.value);
        set({ key: "customerDesc", data: event.target.value })
    };

    const handleCheckboxChange = () => {
        setIsChecked(!isChecked)
    }


    useEffect(() => {
        const cachedData = get("secondLifeProduct")?.data;

        if (cachedData) {
            const updatedData = { ...cachedData, descriptionToCustomer: productDesc };
            set({ key: "secondLifeProduct", data: updatedData });
        } 

        if(isChecked){
            setProductDesc(get("addDesc").data)
        }

    }, [productDesc, isChecked])

    const secondLifeSaveRedirect = () => {
        // redirect('add-label'); updateDesc()
        fetch(`${config.baseUrl}/v1/products`, {
            method: "POST",
            body: JSON.stringify(get("secondLifeProduct").data),
            headers: {
                Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
                "x-api-key": config.apiKey,
                "Content-Type": "application/json",
            },
            credentials: "same-origin",
        })
            .then((response) => response.json())
            .then((res) => {
                setSendProduct(true)
            })
            .catch((error) => console.log(error));
    }

    return (
        <div className="w-full h-full flex items-center mt-[3rem] flex-col" style={{ background: "#fff" }} >
            <div className="w-full h-full flex justify-center flex-col productCard items-center">
                {/* <textarea value={productDesc} onChange={(e) => handleChange(e)} name="desc" id="" cols="30" rows="4" className="w-11/12 border p-2 rounded mt-4" /> */}
                <textarea value={isChecked ? get("addDesc").data : productDesc} onChange={(e) => handleCustomerDescChange(e)} name="desc" id="" cols="30" rows="4" className="w-11/12 border p-2 rounded mt-4 " placeholder={t("CustomerDescription")} disabled={isChecked} />

                <div className="flex w-11/12 mt-2 mb-2 items-center">
                    <input type="checkbox" checked={isChecked} onChange={handleCheckboxChange} id="descTransfer" /> <label className="ml-2" for="descTransfer"> {t("DescTransfer")}</label>
                </div>
                <div className="flex w-11/12 mt-2 mb-2">
                    <span className="mr-1">
                        <svg width="24" height="25" viewBox="0 0 24 25" fill="none" xmlns="http://www.w3.org/2000/svg">
                            <path fillRule="evenodd" clipRule="evenodd" d="M2 12.2197C2 17.7427 6.477 22.2197 12 22.2197C17.523 22.2197 22 17.7427 22 12.2197C22 6.69673 17.523 2.21973 12 2.21973C6.477 2.21973 2 6.69673 2 12.2197ZM20 12.2197C20 16.638 16.4183 20.2197 12 20.2197C7.58172 20.2197 4 16.638 4 12.2197C4 7.80145 7.58172 4.21973 12 4.21973C16.4183 4.21973 20 7.80145 20 12.2197ZM13 15.2197V17.2197H11V15.2197H13ZM13 13.2197V7.21973H11V13.2197H13Z" fill="#E3262F" />
                        </svg>
                    </span>
                    <span>
                        {t("DescWarning")}
                    </span>
                </div>

                <div className="addProductStickyButtonsBg">
                    <button className="w-11/12 addProductStickyButtons z-10" onClick={() => {
                        secondLifeSaveRedirect();
                    }}>{t("SendToApproval")}</button>
                </div>

                <div className={`${sendProduct ? 'block' : 'hidden'}  flex items-center justify-center`}>
                    <div className=" approvedPopUp" />
                    <div className="flex flex-col bg-white w-4/5 h-2/5 justify-around items-center absolute z-10 top-1/3 rounded approvedPopUpScreen" >
                        <div className="p-7 rounded-full" style={{ background: "#E7F3F9" }}>
                            <svg width="41" height="29" viewBox="0 0 41 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                                <path d="M15.902 21.8765L37.5672 0.208984L40.9023 3.54173L15.902 28.542L0.902344 13.5423L4.23509 10.2096L15.902 21.8765Z" fill="#007DBC" />
                            </svg>
                        </div>
                        <span className="text-xl font-bold blue600 text-center w-4/5">{t("NewSecondLifeProductSentToApproval")}</span>
                        <button className="approvedButton" onClick={() => { setSendProduct(false); redirect('/sh-homepage') }}>{t("Okay")}</button>
                    </div>
                </div>
            </div>
        </div>

    );

}


export default AddLabel;