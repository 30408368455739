import React, { useEffect, useState, useRef } from "react";
import { get, set, remove } from "../services/cache";
import { useNavigate, Link, useLocation } from "react-router-dom";
import config from "../services/config";
import { useTranslation } from "react-i18next";
import bicycle from '../assets/bicycle.svg'
import stockManagementIcon from '../assets/stockManagementIcon.svg'
import homeIcon from '../assets/homeIcon.svg'
import storeSettingsIcon from '../assets/storeSettingsIcon.svg'
import backButtonIcon from '../assets/backButtonIcon.svg'
import closeIcon from '../assets/closeIcon.svg'
import logOutIcon from '../assets/logOutIcon.svg'

export const Layout = () => {
  
  const location = useLocation();
  const path = location.pathname;

  const redirect = useNavigate();
  const [navText, setNavText] = useState();

  const sidebarRef = useRef(null);
  const buttonRef = useRef(null);

  const [menuOpen, setMenuOpen] = useState(false);
  const [checkHome, setCheckHome] = useState();
  const [res, setRes] = useState(null);
  const [checkProcess, setCheckProcess] = useState(false);
  const userAccessToken = get("fedidLoginAccessToken");

  const { t, i18n } = useTranslation();

  const genericHamburgerLine = `h-0.5 w-5 my-1 rounded-full ${menuOpen ? "bg-blue-700" : "bg-white"
    } transition ease transform duration-300`;

  const toggleMenu = () => setMenuOpen(!menuOpen);
  let typeOfApp = localStorage.getItem("typeOfApp");

  const titles = {
    '/sh-homepage': `${t("Hello")} ${res?.name}`,
    '/sh-approval': `${t("Approval")}`,
    '/sh-stock-control': `${t("StockControl")}`,
    '/sh-print-alert': `${t("PrintAlert")}`,
    '/preview-photo': `${t("PreviewPhoto")}`,
    '/sh-add-product': `${typeOfApp === 'secondLife' ? t("SecondLifeApp") : t("BuyBackApp")}`,
    '/sh-add-product/details': `${t("ProductDetails")}`,
    '/sh-add-product/condition': `${t("Condition")}`,
    '/sh-add-product/price': `${t("Price")}`,
    '/sh-add-product/add-photo': `${t("AddPhoto")}`,
    '/sh-add-product/add-label': `${typeOfApp === 'secondLife' ? t("SecondLifeApp") : t("BuyBackApp")}`,
    '/sh-add-product/add-information': `${t("ProductInformation")}`,
    '/sh-add-product/add-product-detail-buyback': `${t("ProductInformation")}`,
    '/sh-add-product/contract-buyback': `${t("Contract")}`,
    '/sh-add-product/contract-label-buyback': `${t("Label")}`,
    '/sh-add-product/voucher-buyback': `${t("VoucherCard")}`,
    '/sh-store-settings': `${t("StoreSettings")}`,
    '/sh-stock-management': `${t("StockManagement")}`
  }

  let blackList = ['select-app', 'reader']

  let url;

  useEffect(() => {
    let claimsData = get("claims")?.data;
    if (!checkProcess) {
      if (!claimsData) {
        fetch(`${config.baseUrl}/v1/users/claims`, {
          method: "GET",
          headers: {
            Authorization: `Bearer ${userAccessToken?.data?.access_token}`,
            "x-api-key": config.apiKey,
          },
          credentials: "same-origin",
        })
          .then((response) => response.json())
          .then((res) => res !== null ? setRes(res.data) : redirect("/"))
          .catch((error) => {
            console.log(error);
            if (!userAccessToken.data.access_token) { redirect("/") }
          });
      } else {
        setRes(get("claims").data)
      }
    }
  }, [path])

  useEffect(() => {
    if (res !== null) {
      set({ key: "claims", data: res })
    }
  }, [res?.name])

  useEffect(() => {
    url = window.location.href;

    if (url.includes('homepage')) {
      setCheckHome(true);
    } else { setCheckHome(false) }

    if (blackList.some(i => url.includes(i)) || path === '/') {
      setCheckProcess(true)
    } else { setCheckProcess(false) }

    if (blackList.some(i => url.includes(i))) {
      // redirect('/')
    }
    setNavText(titles[location.pathname])

  });

  const changeDictionary = async lang => {
    await i18n.changeLanguage(lang);
    localStorage.setItem('selectedLanguage', lang);
  }

  return (
    <div>
      <nav className={` w-full p-3 flex content-center justify-between items-center fixed top-0 z-20 ${checkProcess ? 'hidden' : ''}`} style={{ backgroundColor: (typeOfApp === 'secondLife' ? "#00537D" : '#012B49') }}>
        {checkHome ?
          <button
            className="outline-none w-1/6 z-10 absolute"
            onClick={toggleMenu}
            ref={buttonRef}
          >
            <div
              className={`${genericHamburgerLine} ${menuOpen && "rotate-45 translate-y-2"
                }`}
            />
            <div
              className={`${genericHamburgerLine} ${menuOpen && "opacity-0"}`}
            />
            <div
              className={`${genericHamburgerLine} ${menuOpen && "-rotate-45 -translate-y-1"
                }`}
            />
          </button> :
          <button
            id="backButton"
            className="outline-none w-1/6 z-10 absolute"
            onClick={() => {
              if (window.location.pathname === '/sh-add-product') {
                redirect('/sh-homepage')
              } else if (window.location.href.includes("price" || "condition")) {
                redirect('/sh-add-product')
              } else { window.history.back() }
            }}
          >
           <img src={backButtonIcon} alt="" />
          </button>}

        <p className="uppercase text-white font-bold text-md text-center w-full">
          {navText}
        </p>

      </nav>
      <div
        className={`sideBar h-screen w-5/6 pb-5 transform transition-all shadow-2xl fixed top-0 duration-700 text-white flex flex-col justify-between items-center z-[21] ${!menuOpen && "-translate-x-full"
          }`}
        ref={sidebarRef}
        style={{ backgroundColor: (typeOfApp === 'secondLife' ? "#00537D" : '#012B49') }}
      >
        <span
          className="text-blue-700 font-bold absolute right-2.5 top-2.5"
          onClick={toggleMenu}
        >
          <img src={closeIcon} alt="" />
        </span>

        <div className="flex flex-col justify-between w-full h-full">
          <div>
            <div className="text-blue-600 flex flex-col pt-14 w-full pb-14 bg-white items-center" style={{ backgroundColor: "#E7F3F9" }}>
              <div className="absolute left-0">
                <img src={bicycle} alt="" />
              </div>
              <svg width="22" height="29" viewBox="0 0 22 29" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path fillRule="evenodd" clipRule="evenodd" d="M2.99967 8.66602C2.99967 13.086 6.57967 16.666 10.9997 16.666C15.4197 16.666 18.9997 13.086 18.9997 8.66602C18.9997 4.24602 15.4197 0.666016 10.9997 0.666016C6.57967 0.666016 2.99967 4.24602 2.99967 8.66602ZM10.9997 17.9993C5.10864 17.9993 0.333008 22.775 0.333008 28.666H21.6663C21.6663 22.775 16.8907 17.9993 10.9997 17.9993Z" fill={typeOfApp === 'secondLife' ? "#00537D" : '#012B49'} />
              </svg>

              <p className="font-bold text-2xl self-center blue600">{t("Hello")} {res?.name}
              </p>
              <p className="self-center blue500 flex items-center">
                <svg width="12" height="15" viewBox="0 0 12 15" fill="none" xmlns="http://www.w3.org/2000/svg" className="mr-1">
                  <path d="M6 12.9339L9.3 9.63393C9.9526 8.98127 10.397 8.14975 10.577 7.24452C10.7571 6.33928 10.6646 5.401 10.3114 4.5483C9.95817 3.69561 9.36003 2.9668 8.59261 2.45404C7.82519 1.94129 6.92296 1.6676 6 1.6676C5.07704 1.6676 4.17481 1.94129 3.40739 2.45404C2.63997 2.9668 2.04183 3.69561 1.68861 4.5483C1.33539 5.401 1.24294 6.33928 1.42297 7.24452C1.603 8.14975 2.04741 8.98127 2.7 9.63393L6 12.9339ZM6 14.8193L1.75734 10.5766C0.918228 9.73748 0.346791 8.66838 0.115286 7.5045C-0.11622 6.34061 0.00260456 5.13422 0.456732 4.03787C0.91086 2.94151 1.6799 2.00445 2.66659 1.34516C3.65328 0.685876 4.81332 0.333984 6 0.333984C7.18669 0.333984 8.34672 0.685876 9.33342 1.34516C10.3201 2.00445 11.0891 2.94151 11.5433 4.03787C11.9974 5.13422 12.1162 6.34061 11.8847 7.5045C11.6532 8.66838 11.0818 9.73748 10.2427 10.5766L6 14.8193ZM6 7.66727C6.35362 7.66727 6.69276 7.52679 6.94281 7.27674C7.19286 7.02669 7.33334 6.68755 7.33334 6.33393C7.33334 5.98031 7.19286 5.64117 6.94281 5.39112C6.69276 5.14108 6.35362 5.0006 6 5.0006C5.64638 5.0006 5.30724 5.14108 5.05719 5.39112C4.80715 5.64117 4.66667 5.98031 4.66667 6.33393C4.66667 6.68755 4.80715 7.02669 5.05719 7.27674C5.30724 7.52679 5.64638 7.66727 6 7.66727ZM6 9.0006C5.29276 9.0006 4.61448 8.71965 4.11438 8.21955C3.61429 7.71945 3.33334 7.04118 3.33334 6.33393C3.33334 5.62669 3.61429 4.94841 4.11438 4.44831C4.61448 3.94822 5.29276 3.66727 6 3.66727C6.70725 3.66727 7.38552 3.94822 7.88562 4.44831C8.38572 4.94841 8.66667 5.62669 8.66667 6.33393C8.66667 7.04118 8.38572 7.71945 7.88562 8.21955C7.38552 8.71965 6.70725 9.0006 6 9.0006Z" fill={typeOfApp === 'secondLife' ? "#00537D" : '#012B49'} />
                </svg>
                {res?.storeName}</p>
            </div>
            <div className="w-full">
              <Link
                to="/select-app"
                onClick={toggleMenu}
                onBlur={() => setMenuOpen(false)}
                className="active pr-6 py-4 h-16 flex items-center cursor-pointer w-10/12 ml-6"
              >
                <p className="text-lg font-bold text-white ml-2 text-center flex item-center gap-3">
                  <img src={homeIcon} alt="" />
                  {t("HomePage")}
                </p>
              </Link>
              {get("claims")?.data?.role === "Admin" || get("claims")?.data?.role === "OL" || get("claims")?.data?.role === "SL" ?
                <Link
                  to="/sh-stock-management"
                  onClick={toggleMenu}
                  onBlur={() => setMenuOpen(false)}
                  className=" pr-6 py-4 h-16 flex items-center cursor-pointer w-10/12 ml-6 "
                >
                  <p className="text-lg font-bold text-white ml-2 text-center flex item-center gap-3">
                    <img src={stockManagementIcon} alt="" />
                    {t("StockManagement")}
                  </p>
                </Link> : null}

              {get("claims")?.data?.role === "Admin" || get("claims")?.data?.role === "OL" ? <Link
                to="/sh-store-settings"
                onClick={toggleMenu}
                onBlur={() => setMenuOpen(false)}
                className="active pr-6 py-4 h-16 flex items-center cursor-pointer w-10/12 ml-6"
              >
                <p className="text-lg font-bold text-white ml-2 text-center flex item-center gap-3">
                  <img src={storeSettingsIcon} alt="" />
                  {t("StoreSettings")}
                </p>
              </Link> : null}
            </div>
          </div>
          <Link
            to="/"
            onClick={() => { toggleMenu(); remove("claims") }}
            onBlur={() => setMenuOpen(false)}
            className="active pr-6 py-4 h-16 flex items-center cursor-pointer w-10/12 mb-8 ml-6 border-b"
          >
            <p className="text-lg font-bold text-white ml-2 text-center flex item-center gap-3">
             <img src={logOutIcon} alt="" />
              {t("LogOut")}
            </p>
          </Link>
        </div>
        <div className="w-10/12 flex justify-between mb-10">
          <button onClick={() => changeDictionary('tr')} className="flex items-center"> <span className="border-2 rounded-full p-1 mr-2 font-bold">TR</span> Türkçe</button>
          <button onClick={() => changeDictionary('en')} className="flex items-center mr-8"> <span className="border-2 rounded-full p-1 mr-2 font-bold">EN</span> English</button>
        </div>
        <div className="w-10/12">
          D-Care App {process.env.REACT_APP_VERSION}
        </div>
      </div>
      {menuOpen && <button className="absolute bg-black opacity-30 w-full h-full z-20" onClick={toggleMenu} />}
    </div>
  );
};

export default Layout;
